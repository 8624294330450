<template>
  <div class="col-lg-3">
    <div
      class="d-flex align-items-center justify-content-between py-2 px-4 mb-3 bg-primary"
    >
      <h6 class="m-0 text-white">{{ $t("TIPS_BY_COUNTRIES") }}</h6>
    </div>

    <div class="card">
      <div class="card-body">
        <p>
          Want to find tips in another easy way? You can search any tips via
          country here.
        </p>
        <a
          class="btn btn-primary mr-2 btn-sm"
          data-toggle="collapse"
          :href="`#collapse-more-country`"
          role="button"
          aria-expanded="false"
          :aria-controls="`collapse-login`"
          >{{ $t("SHOW_COUNTRY") }}</a
        >
        <div class="collapse mt-4" :id="`collapse-more-country`">
          <ul class="list-group">
            <li class="list-group-item">An item</li>
            <li class="list-group-item">A second item</li>
            <li class="list-group-item">A third item</li>
            <li class="list-group-item">A fourth item</li>
            <li class="list-group-item">And a fifth one</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  mounted() {},
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="scss">
.status {
  background-color: rgb(0, 0, 0);
  border-radius: 4px;
  margin: 2px 0px 0px 0px;
  padding: 0px 6px 3px 6px !important;
  span {
    font-size: 12px;
    color: rgb(255, 255, 255);
  }
}
p {
  font-size: 12px;
}
</style>
